import { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import FourthImage from "../../../assets/images/blogs/slide4.jpg";
import PersonalLoanImage from "../../../assets/images/blogs/personal-loan.jpg";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import { Link } from "react-router-dom";

const HeroSection = styled.header`
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${FourthImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
  }
  .container {
    z-index: 3;
  }
`;
const HeroTitle = styled.h1`
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  z-index: 3;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const HeroSubtitle = styled.p`
  color: #fff;
  font-size: 1rem;
  z-index: 3;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;
const StyledDiv = styled.div`
  &.section {
    margin: 50px 0;
  }
  .main-container {
    padding: 0px 45px;
  }
  .sidebar {
    border: 1px solid transparent;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 10px #d1d1d1;
    border-radius: 5px;
    padding: 10px;
    gap: 15px;
  }
  .sidebar.table-of-content {
    position: sticky;
    top: 90px;
  }
  .category h2,
  h2 {
    color: #1a73e8; /* Adjust color as needed */
  }
  .category-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .card-label {
    height: 45px;
    border: 1px solid #10bd62;
    display: flex;
    gap: 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: linear-gradient(45deg, #000000d9, #04b35f);
    color: #fff;
    padding: 0 25px;
    font-size: 18px;
  }
  .card-label.active {
    background: #1a73e8;
  }
  .actegory-label {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 13px;
    color: #d1d1d1;
    align-items: center;
  }
  .divider {
    width: 7px;
    height: 0px;
    border: 1px solid #d1d1d1;
  }
  .table-of-content-cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .content-label-text {
    text-decoration: underline;
    font-size: 18px;
    color: #04b05e;
  }
  .content-label-text a {
    color: #04b05e;
  }
  .bullet {
    height: 5px;
    width: 5px;
    background: #d1d1d1;
    border-radius: 50px;
  }
  .blog-content {
    font-size: 16px;
  }
  .blog-content img {
    width: 100%;
  }
  .sidebar-parent {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .apply-now-on-loan {
    position: relative;
    width: 100%;
  }
  .apply-now-on-loan img {
    width: 100%;
  }
  .apply-now-on-loan-text {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 100%;
    padding: 45px;
  }
  .apply-now-on-loan-text h2 {
    text-align: center;
    color: #fff;
  }
  .button-name {
    height: 54px;
    padding: 25px;
    border: 2px solid green;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.85), rgb(4, 179, 95));
  }
`;
export default function BlogPostDetail() {
  useEffect(() => {
    const tocItems = document.querySelectorAll(".blog-content h2");
    const tocContainer = document.querySelector(".table-of-content-cards");
    tocContainer.innerHTML = "";
    tocItems.forEach((item) => {
      const tocLink = document.createElement("div");
      tocLink.className = "content-label-text";
      tocLink.innerHTML = `<a href="#${item.id}">${item.innerText}</a>`;
      tocContainer.appendChild(tocLink);
    });

    const links = tocContainer.querySelectorAll("a");
    links.forEach((link) => {
      link.addEventListener("click", (event) => {
        event.preventDefault();
        const targetId = link.getAttribute("href").substring(1);
        const targetElement = document.getElementById(targetId);
        window.scrollTo({
          top: targetElement.offsetTop - 50,
          behavior: "smooth",
        });
      });
    });
  }, []);

  return (
    <>
      <Header />
      <HeroSection
        className="header-image ken-burn-center light"
        data-parallax="true"
        data-natural-height="500"
        data-natural-width="1920"
        data-bleed="0"
        data-image-src={FourthImage}
        data-offset="0"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <HeroTitle>Personal Loan for CIBIL Score above 650</HeroTitle>
              <HeroSubtitle></HeroSubtitle>
            </div>
          </div>
        </div>
      </HeroSection>
      <StyledDiv className="section">
        <div className="main-container">
          <div className="row">
            <div className="col-md-3">
              <div className="sidebar table-of-content">
                <div className="category">
                  <h2>Table of Contents</h2>
                </div>
                <div className="table-of-content-cards">
                  {/* Dynamic content will be inserted here */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="category">
                <h2>Personal Loan for CIBIL Score above 650</h2>
                <div className="actegory-label">
                  <div>By: Admin</div>
                  <div className="divider"></div>
                  <div>23 Jan 2025</div>
                  <div className="bullet"></div>
                  <div>4 Minutes read</div>
                </div>
              </div>
              <div className="blog-content">
                <p>
                  Securing a personal loan for CIBIL score above 650 can be
                  quite overwhelming. Any individual looking to borrow this loan
                  might have a hard time thinking{" "}
                  <strong>
                    “Will I be able to obtain a personal loan for CIBIL score
                    above 650?”
                  </strong>{" "}
                  Fret not! Getting a personal loan with a CIBIL score as low as
                  650 is possible if you follow the right and effective
                  approach. Not only can you get a personal loan, but you can
                  also take the necessary steps to boost your credit score.
                </p>
                <img src={FourthImage} alt="Descriptive Alt Text" />
                <p>
                  Having and maintaining a high CIBIL score is not a piece of
                  cake. Sometimes you run into unexpected financial problems
                  causing your credit score to drop. Stay calm! You can still
                  obtain a personal loan with a CIBIL score above 650.
                </p>
                <p>
                  This blog will discuss how to make the current financial
                  situation work in your favor to get the personal loan you
                  need. After all, who would not want to set themselves on a
                  path to a stronger credit profile?
                </p>
                <h2 id="personal-loan-for-cibil-score-of-550">
                  Personal Loan for CIBIL Score above 650
                </h2>
                <p>
                  A personal loan is an amazingly popular tool as it can be used
                  for numerous reasons and does not require collateral. Being an
                  unsecured loan, the interest rate is likely to be higher. Even
                  its eligibility criteria are more rigid than other secured
                  loans, including auto loans and home loans. This is why most
                  lending agencies dealing in personal loans require individuals
                  to have a credit score of 750 or above. However, some lending
                  institutions like FinLab offer personal loans to applicants
                  with a lower CIBIL score above 650.
                </p>
                <p>
                  Now, the question arises as to whether you can secure a{" "}
                  <strong>personal loan in India</strong> with a CIBIL score
                  above 650. While you may find lending agencies that provide
                  individuals with a personal loan with a CIBIL score as low as
                  650, the terms and conditions will be unpleasant. This is
                  because the lending agency will not trust the borrower and
                  have faith in their repayment ability. Thus, they will avoid
                  providing a loan to the applicant.
                </p>
                <h2 id="factors-contributing-to-a-low-cibil-score-of-550">
                  Factors Contributing to a Low CIBIL Score above 650
                </h2>
                <p>
                  Certain factors may affect your credit score including payment
                  history, length of your credit history, owed liabilities, and
                  more. Thus, your CIBIL score could be impacted because of:
                  <ol>
                    <li>
                      <strong>Untimely Payments:</strong> Missing even one can
                      considerably harm your credit score, signifying poor
                      financial management. Ensure you streamline repayments
                      through debt consolidation to avoid such obstacles.
                    </li>
                    <li>
                      <storng>High Credit Utilization:</storng> When you utilize
                      more than 50% of your available credit limit, it
                      consistently indicates financial instability. Considering
                      this, you must maintain expenses within this threshold
                      because doing so will assure you of a positive credit
                      score.
                    </li>
                    <li>
                      <strong>Several Loan Applications:</strong> Applying for
                      multiple loans simultaneously may depict financial
                      desperation, affecting your borrowing eligibility
                      adversely. Moreover, each lending agency enquiry,
                      classified as a ‘hard enquiry’, causes a drop in your
                      CIBIL score.
                    </li>
                  </ol>
                </p>
                <h2 id="how-can-i-secure-a-personal-loan-for-cibil-score-of-550?">
                  How Can I Secure a Personal Loan for CIBIL Score above 650?
                </h2>
                <p>
                  As mentioned above, getting a{" "}
                  <strong>personal loan for CIBIL score above 650</strong> can
                  be complicated. However, it is not impossible when the right
                  approach is followed. Here is a list of some of the useful
                  steps you must follow:
                  <ol>
                    <li>
                      <strong>Explore Alternative Lending Agencies:</strong>{" "}
                      Traditional banks and financial institutions might be
                      unwilling to approve personal loans for immensely low
                      credit scores. However, online lending agencies and NBFCs
                      (short for Non-banking Financial Companies) can be
                      flexible with their eligibility criteria to{" "}
                      <strong>apply for personal loan</strong> with a low score
                      above 650. These lenders have tailored loan products that
                      attract individuals with a low credit score, although with
                      a high interest rate.
                    </li>
                    <li>
                      <strong>Get a Guarantor:</strong> A guarantor with a
                      strong credit score works to your advantage. They can
                      considerably improve the chances of your loan getting
                      approved. Their credit potential assures the lending
                      agency of repayment.
                    </li>
                    <li>
                      <strong>Proof of Income:</strong> A stable and sufficient
                      income is vital when applying for a personal loan. Ensure
                      you provide documents including bank statements, salary
                      slips, and income tax returns to show lenders that you
                      have a reliable source of income and can easily manage
                      repayments every month.
                    </li>
                  </ol>
                </p>
                <h2 id="what-documents-do">
                  What Documents Do I Need When Applying for a Personal Loan for
                  CIBIL Score above 650?
                </h2>
                <p>
                  Proper documentation is important when applying for a personal
                  loan with a low CIBIL score. Make sure you have the
                  below-mentioned documents:
                  <ul>
                    <li>
                      <strong>Identity Proof:</strong> Documents such as an
                      Aadhaar card, PAN card, voter ID card, or passport are
                      mandatory for identity verification.
                    </li>
                    <li>
                      <strong>Address Proof:</strong> A passport, rental
                      agreement, or utility bill can be considered proof of
                      address.
                    </li>
                    <li>
                      <strong>Employment Proof:</strong> An offer letter from
                      your employer or employment certificate can help prove job
                      stability. These vital documents reassure lending agencies
                      of your stable income.
                    </li>
                    <li>
                      <strong>Income Proof:</strong> Documents like bank
                      statements, salary slips, or ITR (income tax return) are
                      required by lenders to prove the stability of your income
                      and repayment ability
                    </li>
                  </ul>
                </p>
                <h2 id="how-can-i-improve-my-cibil-score">
                  How Can I Improve My CIBIL Score?
                </h2>
                <p>
                  The following are some of the most significant steps you must
                  follow to boost your CIBIL score:
                  <ol>
                    <li>
                      Ensure that you make timely repayments of all your loans.
                      Doing so will help you improve your CIBIL score. Before
                      taking a new loan, clearing pending loans first is
                      important.
                    </li>
                    <li>
                      Ensure you don’t exhaust your credit card limit if you are
                      using one. Your credit utilization ratio must be below
                      30%.
                    </li>
                    <li>
                      Avoid missing any EMI payments! If you cannot pay any of
                      your EMIs on time,
                    </li>
                    <li>
                      It pays to have a mix of loans (secured and unsecured).
                      This will help increase your CIBIL score.
                    </li>
                    <li>
                      Checking your credit score regularly benefits you. Doing
                      so will help ensure that there are no irregularities. If
                      you find any, get them sorted out at the earliest.
                    </li>
                    <li>
                      Have you settled your dues on your credit card or loans?
                      If so, you must ensure they are updated properly in the
                      credit report.
                    </li>
                  </ol>
                  Popularly known as the CIBIL score, the credit score is one of
                  the most crucial factors when applying for a personal loan.
                  This is because a high CIBIL score indicates your ability to
                  make repayments and borrowing capacity.
                </p>
                <h2 id="last-words">Last Words</h2>
                <p>
                  Securing a personal loan for CIBIL score above 650 can help
                  you overcome several obstacles. However, it is not impossible.
                  By following the right approach and useful steps, you can
                  enhance your CIBIL score and your chances of personal loan
                  approval. Increasing your CIBIL score will benefit you in the
                  long run.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="sidebar-parent">
                <div className="sidebar">
                  <div className="category">
                    <h2>Category</h2>
                  </div>
                  <div className="category-cards">
                    <div className="card-label">Home Loan</div>
                    <div className="card-label active">Personal Loan</div>
                    <div className="card-label">Business Loan</div>
                    <div className="card-label">Car Loan</div>
                    <div className="card-label">Emergency Loan</div>
                    <div className="card-label">Medical Loan</div>
                  </div>
                </div>
                <div className="sidebar">
                  <div className="apply-now-on-loan">
                    <img src={PersonalLoanImage} alt="Personal Loan" />
                    <div className="apply-now-on-loan-text">
                      <h2>
                        Get your <strong>Personal Loan</strong> now!
                      </h2>
                      <Link to="/login" className="button-name">
                        For Personal Loan
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="sidebar">
                  <div className="category">
                    <h2>Popular Blogs for you</h2>
                  </div>
                  <div className="table-of-content-cards">
                    <div className="content-label-text">
                      <Link to="#">
                        8 Effective Ways to Use a Personal Loan for Furniture
                      </Link>
                    </div>
                    <div className="content-label-text">
                      <Link to="#">
                        How Can You Get An Instant Medical Loan?
                      </Link>
                    </div>
                    <div className="content-label-text">
                      <Link to="#">
                        Why Take a Personal Loan for Home Interior Redesigning
                      </Link>
                    </div>
                    <div className="content-label-text">
                      <Link to="#">
                        Digital Lending Vs. Traditional Lending: Which One is
                        Better?
                      </Link>
                    </div>
                    <div className="content-label-text">
                      <Link to="#">What is a Gadget Loan?</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledDiv>
      <Footer className={"footer_section custom_footer_style"} />
    </>
  );
}
