import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import FirstImage from "../../../assets/images/blogs/slide1.jpg";
import SecondImage from "../../../assets/images/blogs/slide2.jpg";
import ThirdImage from "../../../assets/images/blogs/slide3.jpg";
import FourthImage from "../../../assets/images/blogs/slide4.jpg";
import { useState } from "react";
import { Link } from "react-router-dom";

const StyledDiv = styled.div`
  .custom-carousel-indicators {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 15;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    margin-right: 21% !important;
    margin-left: 21% !important;
  }
  .custom-carousel-indicators button {
    background-color: #02bd632b;
    border: none;
    width: 10px;
    height: 5px;
    margin: 0 3px;
  }
  .custom-carousel-indicators button.active {
    opacity: 1;
    width: 35px;
    background-color: #02bd64;
  }
  .carousel-caption {
    text-align: left;
    left: 10%;
    right: 10%;
    text-align: left;
    left: 10%;
    right: 10%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .card-carousel {
    display: flex;
    background-color: #5271ff;
    min-width: 110px;
    max-width: 135px;
    justify-content: center;
    align-items: center;
    height: 30px;
  }
  p.posted-by {
    font-size: 13px;
    font-weight: 100;
    text-transform: uppercase;
    margin: 0px;
  }
  .blog-title {
    margin: 0px;
    max-width: 570px;
    position: relative;
    display: inline-block;
  }
  .blog-title::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 0px;
    left: 0;
    background: #02bd64;
    transition: width 0.4s ease;
    -webkit-transition: width 0.4s ease;
  }
  .blog-title:hover::after {
    width: 100%;
    background: #02bd64;
  }
  .text-white {
    color: #fff;
  }
  .section_title {
    padding: 25px;
  }
  .section_title h3 {
    color: #1a73e8;
  }
  .trending-now-div-container {
    -webkit-box-pack: center;
    justify-content: center;
    height: 150px;
    border: 1px solid rgb(193, 193, 193);
    margin-bottom: 55px;
    -webkit-box-align: center;
    align-items: center;
    box-shadow: rgb(54 69 138) 0px 4px 8px;
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
  }

  .trending-now-div {
    font-size: 20px;
    font-weight: 100;
  }
  .main-blog-section {
    width: 100%;
    margin-bottom: 45px;
    border: 1px solid rgb(193, 193, 193);
    padding: 5px;
    box-shadow: rgb(54 69 138) 0px 4px 8px;
    height: 175px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .blog-section {
    display: flex;
    gap: 20px;
    flex-direction: row;
  }
  .blog-image img {
    height: 160px;
    object-fit: cover;
    width: auto;
    max-width: 260px;
  }
  .blog-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 15px;
  }
  .blog-description {
    text-align: justify;
  }
  .blog-date {
    font-size: 10px;
    text-transform: uppercase;
  }
  .blog-title-head {
    font-size: 25px;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    .main-blog-section {
      flex-direction: column;
      height: auto;
    }
    .blog-section {
      flex-direction: column;
    }
    .blog-image img {
      width: 100%;
      height: auto;
    }
    .blog-content {
      padding-right: 0;
    }
  }
  @media (max-width: 576px) {
    .blog-title-head {
      font-size: 20px;
    }
    .blog-description {
      font-size: 12px;
    }
    .blog-date {
      font-size: 8px;
    }
  }
`;
export default function Blogs() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const formatTitleForLink = (title) => {
    return title
      .replace(/[^a-zA-Z0-9]/g, "-")
      .replace(/\s+/g, "-")
      .toLowerCase();
  };

  const blogPosts = [
    {
      title: "Personal Loan for CIBIL Score above 650",
      description: `Securing a personal loan for CIBIL score above 650 can be quite overwhelming. 
        Any individual looking to borrow this loan might have a hard time thinking 
        “Will I be able to obtain a personal loan for CIBIL score above 650?”. 
        Fret not! Getting a personal loan with a CIBIL score as low as 550 
        is possible if you follow the right and effective approach. 
        Not only can you get a personal loan, but you can also take the necessary steps to boost your credit score.`,
      date: "By Finlab Admin - 23 Jan 2025",
      image: FourthImage,
      id: 1,
    },
    // Add more blog posts here
  ];

  return (
    <StyledDiv>
      <Header />
      <header className="" style={{ height: "70vh", position: "relative" }}>
        <Carousel
          fade
          interval={5000}
          controls={false}
          indicators={false}
          activeIndex={activeIndex}
          onSelect={handleSelect}
        >
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={FourthImage}
              alt="First slide"
              style={{ height: "70vh", objectFit: "cover" }}
            />
            <Carousel.Caption className="container">
              <div className="card-carousel">Personal Loan</div>
              <h3 className="blog-title">
                <Link
                  to={`${formatTitleForLink(
                    "Personal Loan for CIBIL Score above 650"
                  )}`}
                  className="text-white"
                >
                  Personal Loan for CIBIL Score above 650
                </Link>
              </h3>
              <p className="posted-by">BY Finlab.one - 23 Jan 2025</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <div className="carousel-indicators custom-carousel-indicators">
          <button
            type="button"
            onClick={() => handleSelect(0)}
            className={activeIndex === 0 ? "active" : ""}
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          {/* <button
            type="button"
            onClick={() => handleSelect(1)}
            className={activeIndex === 1 ? "active" : ""}
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            onClick={() => handleSelect(2)}
            className={activeIndex === 2 ? "active" : ""}
            aria-label="Slide 3"
          ></button> */}
        </div>
      </header>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section_title text-center">
              <h3>Blogs</h3>
            </div>
          </div>
          {blogPosts.map((post, index) => (
            <div className="col-md-12" key={index}>
              <div className="main-blog-section">
                <div className="blog-section">
                  <div className="blog-image">
                    <img src={post.image} alt="blog" />
                  </div>
                  <div className="blog-content">
                    <div className="blog-title blog-title-head">
                      <Link
                        to={`/blogs/${formatTitleForLink(post.title)}`}
                        className="text-dark"
                      >
                        {" "}
                        {post.title}
                      </Link>
                    </div>
                    <div className="blog-description">{post.description}</div>
                    <div className="blog-date">{post.date}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer className={"footer_section custom_footer_style"} />
    </StyledDiv>
  );
}
